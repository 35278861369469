import { defineStore } from 'pinia'
import { createFromDto, LiveStreamMessage } from 'src/types/LiveStreamMessage'
import { useTitleInfoStore } from 'stores/title-info-store'
import { useTaggedMessagesStore } from 'stores/tagged-messages-store'
import { api } from 'boot/axios'
import { Notify } from 'quasar'
import { useTicksStore } from 'stores/ticks-store'
import * as Sentry from '@sentry/vue'
import { Message } from '@stockpulse/typescript-axios'
import { useAuthUserStore } from 'stores/auth-user-store'

export const useLiveStreamStore = defineStore('live-stream', {
    state: () => {
        return {
            initialized: false,
            watermark: new Date(),
            messages: [] as Array<LiveStreamMessage>
        }
    },
    getters: {
        getUnreadLiveStreamMessages: (state) => {
            return (limit: number): Array<LiveStreamMessage> => {
                return state.messages
                    .filter((message: LiveStreamMessage) => !useTaggedMessagesStore().getAllTaggedMessageIds.has(message.id))
                    .sort((a: LiveStreamMessage, b: LiveStreamMessage) => {
                        return b.date.getTime() - a.date.getTime()
                    })
                    .slice(0, limit)
            }
        },
        getUnreadLiveStreamMessagesByTitleId: (state) => {
            return (filterByTitleIds: number[], limit: number): Array<LiveStreamMessage> => {
                return state.messages
                    .filter((liveStreamMessage) => filterByTitleIds.includes(liveStreamMessage.mainTitleId))
                    .filter((message: LiveStreamMessage) => !useTaggedMessagesStore().getAllTaggedMessageIds.has(message.id))
                    .sort((a: LiveStreamMessage, b: LiveStreamMessage) => {
                        return b.date.getTime() - a.date.getTime()
                    })
                    .slice(0, limit)
            }
        }
    },
    actions: {
        async initialize (force = false) {
            if (this.initialized && !force) {
                return
            }

            await this.fetchMessages()

            this.initialized = true
        },
        reset () {
            this.messages = []
            this.initialized = false
            this.initialize(true)
        },
        addLiveStreamMessage (message: LiveStreamMessage): void {
            this.addLiveStreamMessages([message])
        },
        addLiveStreamMessages (messages: LiveStreamMessage[]): void {
            this.$patch((state) => {
                // TODO: Maximum definieren, prüfen und Message zur Not ignorieren/droppen

                messages.forEach((message) => {
                    // Avoid duplicates
                    if (!state.messages.find(item => item.id === message.id)) {
                        state.messages.push(message)
                    }
                })
            })
        },
        markAsRead (message: LiveStreamMessage): void {
            // TODO: Watermark merken und Message nachladen, wenn Minimum erreicht. Watermark am Besten nicht (nur) lokal merken
            //       sondern auf dem Server, so dass der User unabhängig vom Gerät nur neue Nachrichten sieht
            if (this.messages[0].id === message.id) {
                this.messages.shift()
            } else {
                this.messages = this.messages.filter(e => e.id !== message.id)
            }
        },
        async fetchMessages () {
            // TODO: Do not fetch again if same request is still in progress
            // TODO: Let the user define the preferred languages of the messages in the app settings
            // TODO: Sort known title ids. Titles from watchlist should come first

            // Call API only if user is logged in
            if (!useAuthUserStore().loggedIn) {
                return
            }

            const ticksStore = useTicksStore()
            const titleInfoStore = useTitleInfoStore()
            const knownTitleIds = Array.from(titleInfoStore.getAllTitleIds())

            const messagesToAdd: LiveStreamMessage[] = []

            try {
                // TODO: Add notification on error
                const response = await api.get('/tweet-trader/v1/stockgame/untagged-messages', {
                    timeout: 20000,
                    'axios-retry': { retries: 2 }
                })
                let messages = response.data.messages as Message[]

                // Skip already tagged messages
                const allTaggedMessageIds = useTaggedMessagesStore().getAllTaggedMessageIds
                messages = messages.filter((message: Message) => !allTaggedMessageIds.has(message.message_id || -1))

                // TODO: Validate data against types/dtos/Message

                for (let i = 0; i < messages.length; i++) {
                    const message = messages[i]

                    // Skip messages without known Twitter user
                    if (!message.twitter_user) {
                        continue
                    }

                    // Skip messages for unknown titles
                    if (message.titles === undefined) {
                        continue
                    }
                    let knownTitleWithTicks = false
                    for (const title of message.titles) {
                        if (knownTitleIds.includes(title.title_id)) {
                            if (ticksStore.getTick(title.title_id) === undefined) {
                                Sentry.captureException(
                                    new Error('Error: No ticks available for known title'),
                                    { extra: { titleId: title.title_id } }
                                )
                                continue
                            }
                            knownTitleWithTicks = true
                            break
                        }
                    }
                    if (!knownTitleWithTicks) {
                        continue
                    }

                    // TODO: Skip already read messages

                    messagesToAdd.push(createFromDto(message, knownTitleIds))
                }
            } catch (error) {
                Notify.create({
                    type: 'warning',
                    message: 'Error fetching messages!'
                })
                console.error(error)
            }

            if (messagesToAdd.length === 0) {
                Sentry.captureException(new Error('Error: Could not fetch any relevant messages'))
                Notify.create({
                    type: 'negative',
                    message: 'Error: Could not fetch any relevant messages!'
                })
            }

            this.addLiveStreamMessages(messagesToAdd)
        }
    }
})
